import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import '../styles/navigation.scss';
import useScrollListener from '../helpers/useScrollListener';
import logo from '../images/logo.svg';
import logo_scroll from '../images/logo.svg';
import { useLocation } from '@reach/router';
import { WindowSizeContext } from '../layouts';
import arrow1 from '../images/arrowRight.svg';
import arrow2 from '../images/arrowRight.svg';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const Navigation = () => {
  const [navClassList, setNavClassList] = useState(['navigation']);
  const [mobileMenu, setMobileMenu] = useState({
    active: false,
    open: false,
  });
  const [isScrollImage, setIsScrollImage] = useState(false);
  const [isBackTopVisible, setisBackTopVisible] = useState(false);

  const scroll = useScrollListener();
  const location = useLocation();
  const windowSize = React.useContext(WindowSizeContext);

  useEffect(() => {
    if (windowSize <= 768) {
      if (
        scroll.y < 200 ||
        scroll.y + window.innerHeight === document.documentElement.scrollHeight
      ) {
        setisBackTopVisible(false);
        return;
      }

      setisBackTopVisible(true);
      return;
    } else if (scroll.y < 200) {
      setisBackTopVisible(false);
    } else setisBackTopVisible(true);
  }, [scroll.y, windowSize]);

  useEffect(() => {
    const condition = scroll.y > 150 || location.pathname !== '/';
    setIsScrollImage(condition);
  }, [scroll.y, location.pathname, windowSize]);

  useEffect(() => {
    const classList = ['navigation box-shadow'];
    if (scroll.y - scroll.lastY > 0 && scroll.y > 0) classList.push('hide');
    if (scroll.y > 150) {
      classList.push('scroll');
    }

    if (windowSize <= 768) classList.push('overflowMenu');
    if (mobileMenu.active) classList.push('showMobile');
    if (location.pathname === '/') classList.push('home');

    setNavClassList(classList);
  }, [
    scroll.y,
    scroll.lastY,
    windowSize,
    mobileMenu.active,
    location.pathname,
  ]);
  const toggleMobileMenu = () => {
    setMobileMenu((prev) => {
      return {
        ...prev,
        active: !prev.active,
      };
    });
  };

  const handleMobileMenu = (section) => {
    toggleMobileMenu();

    gsap.to(window, {
      duration: 0.5,
      scrollTo: section,
      offsetY: 50,
    });
  };

  const scrollToTop = () => {
    gsap.to(window, { duration: 0.5, scrollTo: { y: 0 } });
  };

  return (
    <>
      {!mobileMenu.active && windowSize < 768 ? (
        <img className='logo-mobile' src={logo} alt='logo' />
      ) : null}
      <div onClick={toggleMobileMenu} className='menu-link-wrapper'>
        <div
          className={
            mobileMenu.active ? 'menu-link menu-trigger-open' : 'menu-link'
          }
        >
          <span className='lines'></span>
        </div>
      </div>
      <div className={mobileMenu.active ? 'overflowMenu' : null}>
        <nav className={navClassList.join(' ')}>
          <div className='container'>
            <Link to='/'>
              {isScrollImage || windowSize < 768 ? (
                <img className='logo' src={logo_scroll} alt='logo' />
              ) : (
                <img className='logo' src={logo} alt='logo' />
              )}
            </Link>
            <div className='menu'>
              {location.pathname !== '/' ? (
                <ul>
                  <li onClick={toggleMobileMenu}>
                    <Link activeClassName='active' to='/'>
                      O nas
                    </Link>
                  </li>
                  <li onClick={toggleMobileMenu}>
                    <Link activeClassName='active' to='/cennik'>
                      Cennik
                    </Link>
                  </li>
                  <li onClick={toggleMobileMenu}>
                    <Link activeClassName='active' to='/kontakt'>
                      Kontakt
                    </Link>
                  </li>
                  <li onClick={toggleMobileMenu}>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://booksy.com/pl-pl/15274_klaudia-lejko-wojcik-swiat-masazu_masaz_15608_poznan'
                    >
                      {' '}
                      Umów wizytę
                    </a>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li onClick={() => handleMobileMenu('#o-firmie')}>
                    <Link activeClassName='active' to='/'>
                      O nas
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('#flota')}>
                    <Link activeClassName='active' to='/cennik'>
                      Cennik
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('#kariera')}>
                    <Link activeClassName='active' to='/kontakt'>
                      Kontakt
                    </Link>
                  </li>
                  <li onClick={() => handleMobileMenu('#kontakt')}>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://booksy.com/pl-pl/15274_klaudia-lejko-wojcik-swiat-masazu_masaz_15608_poznan'
                    >
                      Umów wizytę
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
      </div>
      {isBackTopVisible ? (
        <div onClick={() => scrollToTop()} className='scroll-top'>
          <div className='arrows'>
            <img className='arrow1' src={arrow1} alt='' />
            <img className='arrow2' src={arrow2} alt='' />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Navigation;
